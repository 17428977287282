import React, { FC, useEffect, useState } from 'react';
import './HotelView.css';
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import { GeoSearchRequest, Hotel, Price, RoomInfo, hotelSearch } from '../../clients/HotelsClient';
import { Link, useLocation, useParams } from 'react-router-dom';
import { constructUrl, getObject } from '../../utils/QueryParamUtil';
import { UUID } from 'crypto';
import LoadingBar from '../LoadingBar/LoadingBar';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { SelectedHotel } from '../../types/SelectedHotel';
import { SelectedRoom } from '../../types/SelectedRoom';
import { calculateNights } from '../../utils/DateUtils';
import { HoperfyEvent, getEvent } from '../../clients/DirectusClient';
import Big from 'big.js';
import { getTotalPrice } from '../../utils/PriceUtils';

interface HotelViewProps { }


interface Slide {
    src: string,
    width: number,
    height: number
}

interface RoomDetails extends RoomInfo {
    totalPrice: Price,
    totalPricePerNight: Price
}

interface EnrichedRoomType {
    roomId: string;
    name: string;
    beddingType: string;
    breakfastIncluded: boolean;
    freeCancellationUntil: Date
    hotelName: string;
    amenities: string[];
    images: string[];
    roomInfoList: RoomDetails[];
}

interface EnrichedHotelResult {
    hotel: Hotel;
    roomTypeList: EnrichedRoomType[];
    startingFrom: Price;
    dailyPricesStartFrom: Price;
    roomsAvailable: number;
}

interface RoomLightboxState {
    [roomId: string]: {
        images: Slide[];
        isOpen: boolean;
    };
}
const HotelView: FC<HotelViewProps> = () => {
    const [hotel, setHotel] = useState<EnrichedHotelResult | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedRooms, setSelectedRooms] = useState<SelectedRoom[]>([])
    const [index, setIndex] = useState<number>(-1);
    const [hotelImages, setHotelImages] = useState<Slide[]>([])
    const [event, setEvent] = useState<HoperfyEvent>()
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const { hotelId } = useParams();
    const { eventSlug } = useParams();
    const [roomLightboxState, setRoomLightboxState] = useState<RoomLightboxState>({});


    const location = useLocation();
    const geoSearchRequest = getObject(new URLSearchParams(location.search)) as GeoSearchRequest;

    const longitudeToRemove: keyof GeoSearchRequest = 'longitude';
    const latitudeToRemove: keyof GeoSearchRequest = 'latitude';
    const radiusToRemove: keyof GeoSearchRequest = 'radius';

    const { [longitudeToRemove]: removedKey, [latitudeToRemove]: removedKey2, [radiusToRemove]: removedKey3, ...baseSearch } = geoSearchRequest;


    const handleChange = (reactEvent: React.ChangeEvent<HTMLSelectElement>) => {
        const [selectedNumber, selectedId, selectedPrice, selectedCurrencyCode, selectedCurrencySymbol, pricePerNight] = reactEvent.target.value.split('|');


        const newSelectedRooms = [...selectedRooms]


        if (+selectedNumber === 0) {
            const indexToRemove = selectedRooms.findIndex(room => room.roomId === selectedId)
            if (indexToRemove >= 0) {
                newSelectedRooms.splice(indexToRemove, 1)
            }
        } else {
            const selectedRoomType = hotel?.roomTypeList.find(x => x.roomId === selectedId);


        const price = {
            amount: new Big(selectedPrice),
            currency: {
                currencyCode: selectedCurrencyCode,
                currencySymbol: selectedCurrencySymbol
            }
        }


        const selectedPricePerNight = {
            amount: new Big(pricePerNight),
            currency: {
                currencyCode: selectedCurrencyCode,
                currencySymbol: selectedCurrencySymbol
            }
        }
        const totalPrice = getTotalPrice(price, event!)
        const totalPricePernigh = getTotalPrice(selectedPricePerNight, event!);
            const selectedRoom = {
                roomId: selectedRoomType?.roomId,
                roomName: selectedRoomType?.name,
                price: {
                    amount: price.amount.toNumber() as any,
                    currency: price.currency
                },
                totalPrice: {
                    amount: totalPrice.amount.toNumber() as any,
                    currency: totalPrice.currency
                },
                totalPricePerNight: {
                    amount: totalPricePernigh.amount.toNumber() as any,
                    currency: totalPricePernigh.currency
                },
                amount: +selectedNumber,
                breakfastIncluded: selectedRoomType?.breakfastIncluded,
                freeCancellationUntil: selectedRoomType?.freeCancellationUntil
            } as SelectedRoom


            const roomIndex = selectedRooms.findIndex(room => room.roomId === selectedRoom.roomId);
            if (roomIndex >= 0) {
                newSelectedRooms[roomIndex] = selectedRoom
            } else {
                newSelectedRooms.push(selectedRoom)
            }
        }

        setSelectedRooms(newSelectedRooms)
    }
    useEffect(() => {
        fetchHotels(eventSlug || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleToggle = () => {
        setIsExpanded(prevState => !prevState);
    };

    const handleRoomImageClick = (roomId: string) => {
        setRoomLightboxState(prevState => ({
            ...prevState,
            [roomId]: {
                ...prevState[roomId],
                isOpen: true
            }
        }));
    };
    const handleCloseLightbox = (roomId: string) => {
        setRoomLightboxState(prevState => ({
            ...prevState,
            [roomId]: {
                ...prevState[roomId],
                isOpen: false
            }
        }));
    };

    const fetchHotels = async (slug: string) => {
        const event = await getEvent(slug);
        setEvent(event)

        setLoading(true);
        setError(null);

        try {
            const hotelSearchRequest = {
                ...baseSearch,
                hotelId: hotelId as UUID
            };

            const result = await hotelSearch(hotelSearchRequest);

            const roomTypeList = result.roomTypeList = result.roomTypeList.map(x => ({
                ...x,
                roomInfoList: x.roomInfoList.map(y => ({
                    ...y,
                    totalPrice: getTotalPrice(y.price, event),
                    totalPricePerNight: getTotalPrice(y.pricePerNight, event)
                }))
            }));
            const enrichedResult = {
                ...result,
                roomTypeList: roomTypeList
            }


            setHotel(enrichedResult);
            const slides = result.hotel.images.map(image => {
                return { src: constructUrl('1024x768', image), width: 1024, height: 768 }
            })

            setHotelImages(slides)

            const initialRoomLightboxState: RoomLightboxState = {};
            result.roomTypeList.forEach(roomType => {
                initialRoomLightboxState[roomType.roomId] = {
                    images: roomType.images.map(image => ({
                        src: constructUrl('1024x768', image),
                        width: 1024,
                        height: 768
                    })),
                    isOpen: false
                };
            });

            setRoomLightboxState(initialRoomLightboxState);
        } catch (error) {
            setError('Error searching hotels');
        } finally {
            setLoading(false);
        }
    };


    const randomSeeded = (textSeed: string): number => {
        let hash = 0;
        for (let i = 0; i < textSeed.length; i++) {
            hash = textSeed.charCodeAt(i) + ((hash << 5) - hash);
        }
        let seed = Math.abs(hash);
        seed = (seed * 9301 + 49297) % 233280;
        return seed / 233280;
        }


    return (<div className="antialiased h-screen overflow-x-hidden">

        {event &&
            <Navigation event={event} />
        }
        {loading && (
            <LoadingBar loading={loading} />
        )}


        {error && <p>{error}</p>}
        <main className="bg-white rounded-t-2xl -mt-10 flex flex-col items-center px-10 py-10 relative z-10">

            <section className="w-full max-w-screen-xl py-6 mx-auto">
                {hotel?.hotel.stars &&

                    <div className="flex items-center gap-0.5 text-amber-300 mb-2">
                        {Array.from(Array(hotel.hotel.stars), (e, i) => {
                            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-5">
                                <path fillRule="evenodd" d="M8 1.75a.75.75 0 0 1 .692.462l1.41 3.393 3.664.293a.75.75 0 0 1 .428 1.317l-2.791 2.39.853 3.575a.75.75 0 0 1-1.12.814L7.998 12.08l-3.135 1.915a.75.75 0 0 1-1.12-.814l.852-3.574-2.79-2.39a.75.75 0 0 1 .427-1.318l3.663-.293 1.41-3.393A.75.75 0 0 1 8 1.75Z" clipRule="evenodd"></path>
                            </svg>
                        })}
                    </div>
                }
                <div className="flex flex-col gap-1.5 mb-10">
                    <div className="flex">
                        <h1 className="font-bold tracking-tighter text-4xl">{hotel?.hotel.name}</h1>

                        {loading &&
                            <span className="h-10 w-[40%] bg-neutral-100 rounded-xl"></span>
                        }
                    </div>
                    {hotel?.hotel.contactDTO.address &&
                        <div className="flex items-center gap-2.5">
                            <div className="flex items-center gap-1">
                                <span className="font-medium">{hotel?.hotel.contactDTO.address}</span>
                            </div>
                            <div className="h-0.5 w-0.5 bg-black/20"></div>
                            <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(hotel?.hotel.name || '')}}${encodeURIComponent(hotel?.hotel.contactDTO.address || '')}`} target="_blank" rel='noreferrer' className="font-medium opacity-60 underline underline-offset-2 decoration-neutral-300 hover:decoration-neutral-600">Show on map</a>
                        </div>
                    }

                    {loading &&
                        <span className="h-6 w-[50%] bg-neutral-100 rounded-lg"></span>
                    }
                </div>

                <div className="flex flex-col gap-8">

                    <div className="flex-1 flex flex-col gap-10">

                        <div>

                            {loading &&
                                <div className="w-full h-[400px] bg-neutral-100 rounded-lg animate-pulse flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-10 opacity-10"><path d="M16 21L11.2381 12.2698L15 6L23 21H16ZM8 10L14 21H2L8 10ZM5.5 8C4.11929 8 3 6.88071 3 5.5C3 4.11929 4.11929 3 5.5 3C6.88071 3 8 4.11929 8 5.5C8 6.88071 6.88071 8 5.5 8Z"></path></svg>
                                </div>
                            }

                            <div className="grid md:grid-cols-2 gap-4">

                                <div className='cursor-pointer'>
                                    {hotel?.hotel.images.slice(0, 1).map((image, index) => (
                                        <img
                                            className="w-full h-full object-cover content-center rounded-2xl"
                                            src={constructUrl('1024x768', image)}
                                            alt={constructUrl('1024x768', image)}
                                            onClick={() => setIndex(0)}
                                        />
                                    ))}
                                </div>

                                <PhotoAlbum
                                    layout="columns"
                                    photos={hotelImages.slice(1, 5)}
                                    targetRowHeight={150}
                                    columns={2}
                                    onClick={({ index: current }) => setIndex(current + 1)}
                                />

                            </div>

                            <Lightbox
                                index={index}
                                slides={hotelImages}
                                open={index >= 0}
                                close={() => setIndex(-1)}
                            />

                        </div>

                        <div className="flex lg:flex-row flex-col items-start gap-12">

                            <div className="flex-1 flex flex-col gap-10">

                                <div className="flex flex-col gap-8">

                                    <span className="text-2xl font-semibold">About</span>

                                    {/*TODO: Remove class: max-h-[244px], when "" button is clicked.*/}

                                    {loading &&
                                        <span className="h-6 w-[50%] bg-neutral-100 rounded-lg"></span>
                                    }

                                    <div className={`relative overflow-hidden ${isExpanded ? '' : 'max-h-[244px]'}`}>

                                        <div className="flex flex-col gap-8">
                                            {hotel?.hotel.descriptions.map((description) => (
                                                <div className="flex flex-col gap-3">
                                                    <h2 className="font-semibold">{description.title}</h2>
                                                    <div className="leading-relaxed">{description.description}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {!isExpanded && (
                                            <div className="h-14 absolute w-full bottom-0 bg-gradient-to-t from-white"></div>
                                        )}
                                    </div>

                                    <button
                                        onClick={handleToggle}
                                        className="flex items-center gap-1 opacity-50 hover:opacity-100 cursor-pointer underline underline-offset-2"
                                    >
                                        <span className="font-medium">{isExpanded ? 'Show less' : 'Show more'}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4">
                                            <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                                        </svg>
                                    </button>

                                </div>

                                <hr className="border-neutral-200 my-2" />

                                <div className="flex flex-col gap-8">

                                    <div className="grid grid-cols-2 gap-8">
                                        <div className="flex flex-col gap-1">
                                            <span className="font-medium text-sm opacity-50">Check-in after</span>
                                            {loading &&
                                                <span className="h-5 w-[25%] bg-neutral-100 rounded-lg"></span>
                                            }
                                            <span className="font-medium text-lg">{hotel?.hotel.checkInTime.toString().substring(0, 5)}</span>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <span className="font-medium text-sm opacity-50">Checkout before</span>
                                            {loading &&
                                                <span className="h-5 w-[25%] bg-neutral-100 rounded-lg"></span>
                                            }
                                            <span className="font-medium text-lg">{hotel?.hotel.checkOutTime.toString().substring(0, 5)}</span>
                                        </div>
                                    </div>

                                </div>

                                <hr className="border-neutral-200 my-2" />

                                <div className="flex flex-col gap-8">

                                    <div className="md:sticky -top-px py-8 -my-8 z-10">
                                        <div className="flex items-center justify-between bg-white pt-8 -mt-8">
                                            <span className="text-2xl font-semibold">Availability</span>
                                            <span className="font-medium opacity-40">Select rooms</span>
                                        </div>
                                        <div className="h-12 w-full absolute bg-gradient-to-b from-white -bottom-4 pointer-events-none" />
                                    </div>


                                    <div className="flex flex-col gap-4">

                                        {hotel?.roomTypeList.map(roomType => (
                                            <div className="rounded-2xl border border-neutral-200 p-5 flex flex-col md:flex-row md:items-center justify-between gap-4">

                                                <Lightbox
                                                    slides={roomLightboxState[roomType.roomId]?.images || []}
                                                    open={roomLightboxState[roomType.roomId]?.isOpen || false}
                                                    close={() => handleCloseLightbox(roomType.roomId)}
                                                    carousel={{ finite: true }}
                                                />

                                                <div className="flex-1 flex items-center gap-5">
                                                    <div className="w-20 h-20 shrink-0 relative group cursor-pointer" onClick={() => handleRoomImageClick(roomType.roomId)}>
                                                        <div className="opacity-0 group-hover:opacity-100 transition ease-in-out absolute h-full w-full bg-black/60 backdrop-blur-sm rounded-xl z-10 top-0 text-white flex items-center justify-center text-xs font-medium">
                                                            <span>Show room</span>
                                                        </div>
                                                        <img className="h-full w-full object-cover content-center rounded-xl" src={constructUrl('1024x768', roomType.images[0])} alt={constructUrl('1024x768', roomType.images[0])} />
                                                    </div>
                                                    <div className="flex-1 flex flex-col gap-1">
                                                        <div className="flex flex-col gap-0.5">
                                                            <span className="font-medium leading-5 block">{geoSearchRequest.rooms.length > 1 ? geoSearchRequest.rooms.length + ' x ' : ''}{roomType.name}</span>
                                                            <div className="text-sm opacity-60">
                                                                {roomType.freeCancellationUntil ?
                                                                    <span>Free cancellation before {new Date(roomType.freeCancellationUntil).getDate()} {new Date(roomType.freeCancellationUntil).toLocaleString('en-US', { month: 'short' })} {new Date(roomType.freeCancellationUntil).getFullYear()}</span>
                                                                    :
                                                                    <span>No free cancellation</span>
                                                                }
                                                            </div>
                                                            <div className="flex flex-wrap gap-2 items-center mt-2">
                                                                {geoSearchRequest.rooms.map((room, index) => (
                                                                    <div
                                                                        key={`room-${index}`}
                                                                        className="flex items-center gap-1 border border-black p-1 rounded mb-1"
                                                                    >
                                                                        {Array.from({ length: room.adults }).map((_, i) => (
                                                                            <svg
                                                                                key={`adult-icon-${index}-${i}`}
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 24 24"
                                                                                fill="currentColor"
                                                                                className="h-4 w-4 text-black"
                                                                            >
                                                                                <path d="M12 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm-7 9a1 1 0 0 1-1-1v-2c0-2.21 1.79-4 4-4h8c2.21 0 4 1.79 4 4v2a1 1 0 0 1-1 1H5Z" />
                                                                            </svg>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="flex gap-1.5 flex-wrap items-center">
                                                            {roomType.beddingType &&
                                                                <div className="flex gap-1.5 flex-wrap items-center">
                                                                    <div className="flex items-center gap-1 text-xs font-medium text-neutral-600 bg-black/5 px-1.5 py-0.5 rounded-lg">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 opacity-70"><path d="M22 11V20H20V17H4V20H2V4H4V14H12V7H18C20.2091 7 22 8.79086 22 11ZM8 13C6.34315 13 5 11.6569 5 10C5 8.34315 6.34315 7 8 7C9.65685 7 11 8.34315 11 10C11 11.6569 9.65685 13 8 13Z"></path></svg>
                                                                        <span>{roomType.beddingType}</span>
                                                                    </div>
                                                                    <div className="h-0.5 w-0.5 bg-black/30"></div>
                                                                </div>
                                                            }
                                                            {roomType.breakfastIncluded ?
                                                                <div className="flex items-center gap-1 text-xs font-medium text-neutral-600 bg-black/5 px-1.5 py-0.5 rounded-lg">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 opacity-70"><path d="M21 2V22H19V14H16V7C16 4.23858 18.2386 2 21 2ZM9 13.9V22H7V13.9C4.71776 13.4367 3 11.419 3 9V3H5V10H7V3H9V10H11V3H13V9C13 11.419 11.2822 13.4367 9 13.9Z"></path></svg>
                                                                    <span>Breakfast included</span>
                                                                </div>
                                                                :
                                                                <div className="flex items-center gap-1 text-xs font-medium text-neutral-600 bg-black/5 px-1.5 py-0.5 rounded-lg">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 opacity-70"><path d="M21 2V22H19V14H16V7C16 4.23858 18.2386 2 21 2ZM9 13.9V22H7V13.9C4.71776 13.4367 3 11.419 3 9V3H5V10H7V3H9V10H11V3H13V9C13 11.419 11.2822 13.4367 9 13.9Z"></path></svg>
                                                                    <span>Breakfast not included</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="md:hidden border-neutral-200 my-2" />

                                                <div className="flex items-center justify-end gap-6">
                                                    <div className="flex flex-col items-end justify-end">
                                                        <span className="text-xs opacity-60">Price for {calculateNights(geoSearchRequest.checkIn, geoSearchRequest.checkOut)} nights</span>
                                                        <span className="font-medium shrink-0">{roomType.roomInfoList[0].totalPrice.currency.currencySymbol} {roomType.roomInfoList[0].totalPrice.amount.toString()}</span>
                                                    </div>
                                                    <select className="bg-neutral-200 rounded-xl p-2 focus:outline-none" onChange={handleChange}>
                                                        <option key={roomType.roomId} selected value={`0|${roomType.roomId}`}>0</option>
                                                        {Array.from({ length: Math.floor(randomSeeded(roomType.roomId) * 3) + 1 }, (_, index) => (
                                                            <option key={roomType.roomId + index} value={`${index + 1}|${roomType.roomId}|${roomType.roomInfoList[0].price.amount}|${roomType.roomInfoList[0].price.currency.currencyCode}|${roomType.roomInfoList[0].price.currency.currencySymbol}|${roomType.roomInfoList[0].pricePerNight.amount}`}>{index + 1}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>
                                        ))}

                                    </div>

                                </div>

                                <hr className="border-neutral-200 my-2" />




                                {hotel && hotel.hotel.amenities && (
                                    <div className="flex flex-col gap-8">
                                        <span className="text-2xl font-semibold">Facilities</span>

                                        {loading && (
                                            <span className="h-6 w-[50%] bg-neutral-100 rounded-lg"></span>
                                        )}

                                        <div className={`relative overflow-hidden transition-all duration-300 ${isExpanded ? '' : 'max-h-[124px]'}`}>
                                            <div className={`grid grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-5 ${isExpanded ? 'block' : 'overflow-hidden'}`}>
                                                {hotel.hotel.amenities.map((amenity, index) => (
                                                    <div key={index} className="flex items-center gap-2 text-base font-medium">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 shrink-0">
                                                            <path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clipRule="evenodd" />
                                                        </svg>
                                                        <span>{amenity}</span>
                                                    </div>
                                                ))}
                                            </div>
                                            {!isExpanded && (
                                                <div className="h-14 absolute w-full bottom-0 bg-gradient-to-t from-white"></div>
                                            )}
                                        </div>

                                        <button
                                            onClick={handleToggle}
                                            className="flex items-center gap-1 opacity-50 hover:opacity-100 cursor-pointer underline underline-offset-2"
                                        >
                                            <span className="font-medium">{isExpanded ? 'Show Less' : 'Show More'}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4">
                                                <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                )}




                                <hr className="border-neutral-200 my-2" />

                            </div>

                            <div className="flex-1 w-full lg:max-w-[360px] xl:max-w-[400px] md:sticky top-6">

                                <aside className="w-full p-6 rounded-2xl border border-neutral-200 flex flex-col gap-4">

                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center gap-2.5">
                                            <div className="px-4 py-3.5 flex flex-col flex-1 bg-neutral-100 rounded-xl cursor-not-allowed">
                                                <span className="text-xs opacity-60">Check-in</span>
                                                <span className="text-sm font-medium">{new Date(baseSearch.checkIn).toISOString().substring(0, 10)}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 opacity-50">
                                                <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                            </svg>
                                            <div className="px-4 py-3.5 flex flex-col flex-1 bg-neutral-100 rounded-xl cursor-not-allowed">
                                                <span className="text-xs opacity-60">Check-out</span>
                                                <span className="text-sm font-medium">{new Date(baseSearch.checkOut).toISOString().substring(0, 10)}</span>
                                            </div>
                                        </div>
                                        {/* <div className="w-full bg-neutral-100 border border-transparent focus-within:border-neutral-200 focus-within:bg-white flex flex-col px-5 py-3.5 rounded-2xl">
                                            <span className="text-xs opacity-60 my-px">Guests per room</span>
                                            <input value={baseSearch.rooms[0].adults} type="number" disabled={true} className="font-medium text-sm p-0 border-0 bg-transparent focus:outline-none focus:ring-0 leading-5" />
                                        </div> */}
                                    </div>

                                    <hr className="border-neutral-100 my-2" />

                                    {selectedRooms.length !== 0 &&
                                        <ul className="flex flex-col divide-y -my-6">
                                            {selectedRooms.map(selectedRoom =>
                                                Array.from({ length: selectedRoom.amount }).map((_, index) => (
                                                    <li className="flex items-center justify-between gap-10 py-6">
                                                        <div className="flex flex-col">
                                                            <span className="font-medium leading-4 mb-1.5">{geoSearchRequest.rooms.length > 1 ? geoSearchRequest.rooms.length + ' x ' : ''}{selectedRoom.roomName}</span>
                                                            <div className="text-xs opacity-60">
                                                                {selectedRoom.freeCancellationUntil ?
                                                                    <span>Free cancellation until: {new Date(selectedRoom.freeCancellationUntil).getDate()} {new Date(selectedRoom.freeCancellationUntil).toLocaleString('en-US', { month: 'short' })} {new Date(selectedRoom.freeCancellationUntil).getFullYear()}</span>
                                                                    :
                                                                    <span>No free cancellation</span>
                                                                }
                                                            </div>
                                                            <span className="py-1.5 flex items-center gap-0.5 text-sm opacity-60">
                                                                <span>{selectedRoom.totalPricePerNight.currency.currencySymbol} {selectedRoom.totalPricePerNight.amount.toString()}</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-3.5">
                                                                    <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                                                                </svg>
                                                                <span>{calculateNights(baseSearch.checkIn, baseSearch.checkOut)} nights</span>
                                                            </span>
                                                            <div className="flex flex-wrap gap-2">
                                                                {selectedRoom.breakfastIncluded ?
                                                                    <div className="flex items-center gap-1 text-xs font-medium text-neutral-600 bg-black/5 px-1.5 py-0.5 rounded-lg">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 opacity-70"><path d="M21 2V22H19V14H16V7C16 4.23858 18.2386 2 21 2ZM9 13.9V22H7V13.9C4.71776 13.4367 3 11.419 3 9V3H5V10H7V3H9V10H11V3H13V9C13 11.419 11.2822 13.4367 9 13.9Z"></path></svg>
                                                                        <span>Breakfast included</span>
                                                                    </div>
                                                                    : <div className="flex items-center gap-1 text-xs font-medium text-neutral-600 bg-black/5 px-1.5 py-0.5 rounded-lg">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 opacity-70"><path d="M21 2V22H19V14H16V7C16 4.23858 18.2386 2 21 2ZM9 13.9V22H7V13.9C4.71776 13.4367 3 11.419 3 9V3H5V10H7V3H9V10H11V3H13V9C13 11.419 11.2822 13.4367 9 13.9Z"></path></svg>
                                                                        <span>Breakfast included</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <span className="shrink-0 font-medium">{selectedRoom.totalPrice.currency.currencySymbol} {new Big(selectedRoom.totalPrice.amount).toString()}</span>
                                                    </li>)
                                                ))}
                                        </ul>
                                    }

                                    {selectedRooms.length === 0 &&
                                        <div className="flex items-center justify-center py-4">
                                            <span className="text-center opacity-50">No rooms selected</span>
                                        </div>
                                    }

                                    <hr className="border-neutral-100 my-2" />

                                    <div className="flex items-center justify-between">
                                        <span className="font-medium">Total</span>
                                        <span className="text-xl font-semibold">{selectedRooms.length > 0 ? selectedRooms[0].price.currency.currencySymbol : hotel?.dailyPricesStartFrom.currency.currencySymbol} {selectedRooms.reduce((accumulator, currentValue) => accumulator + new Big(currentValue.totalPrice.amount).times(currentValue.amount).toNumber(),
                                            0)}</span>
                                    </div>

                                    <hr className="border-neutral-100 my-2" />

                                    <Link className="flex flex-col bg-white rounded-[14px] relative group" to={`/${eventSlug}/checkout`} state={{ hotel: hotel?.hotel, rooms: selectedRooms, search: baseSearch } as SelectedHotel}>
                                        <button disabled={selectedRooms.length <= 0} className="bg-[#1a56db] p-4 text-white rounded-xl font-medium tracking-wide flex items-center justify-center gap-2">
                                            <span>Continue to Checkout</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h4.59l-2.1 1.95a.75.75 0 0 0 1.02 1.1l3.5-3.25a.75.75 0 0 0 0-1.1l-3.5-3.25a.75.75 0 1 0-1.02 1.1l2.1 1.95H6.75Z" clip-rule="evenodd" />
                                            </svg>

                                        </button>
                                    </Link>

                                </aside>

                                <div className="flex items-center justify-center gap-1 py-6 opacity-50">
                                    <span className="font-medium text-xs">Powerd by</span>
                                    <img className="h-3.5 -mb-0.5" src="https://develop.travel.hoperfy.com/logo.png" alt="Hoperfy logo" />
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>

        </main>

        <Footer />

    </div>

    );
}

export default HotelView;
